import { Dispatch, SetStateAction, useState } from "react";
import { Container, Dialog } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { RootState, getAllReseedEventsForFarm, useAppDispatch } from "model";
import styled from "styled-components";
import { useNotification } from "app/NotificationProvider";
import { useTranslation } from "common/locales";
import { mixpanel } from "common/analytics";
import { GRASS_TYPE, fetchFieldsById } from "model/fieldSlice";
import { SEEDING_METHOD } from "model/reseedEventSlice";
import { updateReseedEvent, Field, ReseedEvent } from "model";
import EnterReseedEvent from "./EnterReseedEvent";
import { useIntl } from "react-intl";
const StyledContainer = styled(Container)`
  padding-top: 50px;
  padding-bottom: 70px;
`;

export default function UpdateReseedEvent({
  field,
  reseedEvent,
  dialogOpen,
  setDialogOpen,
}: {
  field: Field;
  reseedEvent: ReseedEvent;
  dialogOpen: boolean;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const { t } = useTranslation();

  const currentFarmId = useSelector(
    (state: RootState) => state.farms.currentFarmId
  );

  const handleClose = () => {
    setDialogOpen(false);
    mixpanel.track("Update re seed event cancelled");
  };

  const [newGrassType, setNewGrassType] = useState<GRASS_TYPE | undefined>(
    reseedEvent.newGrassType
  );

  const [grassTypeError, setGrassTypeError] = useState<boolean>(false);

  const [seedingMethod, setSeedingMethod] = useState<
    SEEDING_METHOD | undefined
  >(reseedEvent.seedingMethod);

  const [seedingMethodError, setSeedingMethodError] = useState<boolean>(false);

  const [notes, setNotes] = useState<string | undefined>(reseedEvent.note);

  const [reseedDate, setReseedDate] = useState<Date | null>(
    new Date(Date.parse(reseedEvent.reseedDate))
  );

  const [reseedDateError, setReseedDateError] = useState<boolean>(false);

  function callUpdateReseedEvent() {
    if (!newGrassType || !reseedDate || !seedingMethod) {
      return;
    }

    return dispatch(
      updateReseedEvent({
        fieldGuid: field.guid,
        newGrassType: newGrassType,
        guid: reseedEvent.guid,
        note: notes ?? "",
        seedingMethod: seedingMethod,
        reseedDate: reseedDate.toISOString(),
      })
    );
  }

  async function handleSave() {
    // check for wrong input
    if (!newGrassType) {
      setGrassTypeError(true);
    }
    if (!reseedDate) {
      setReseedDateError(true);
    }
    if (!seedingMethod) {
      setSeedingMethodError(true);
    }

    if (!newGrassType || !reseedDate || !seedingMethod || reseedDateError) {
      return;
    }

    await callUpdateReseedEvent();
    await dispatch(fetchFieldsById([field.guid])); // updating the grass type if needed
    await dispatch(getAllReseedEventsForFarm({ farmId: currentFarmId ?? "" }));
    notify(t("editReseedEvent.success"));
    mixpanel.track("Update re seed event successful");
    handleClose();
  }
  const { formatMessage: f } = useIntl();

  return (
    <Dialog disableEscapeKeyDown open={dialogOpen} onClose={handleClose}>
      <StyledContainer>
        <EnterReseedEvent
          disabled={false}
          field={field}
          setNewGrassType={setNewGrassType}
          setNotes={setNotes}
          reseedDate={reseedDate}
          setReseedDate={setReseedDate}
          grassTypeError={grassTypeError}
          reseedDateError={reseedDateError}
          setGrassTypeError={setGrassTypeError}
          setSeedingMethod={setSeedingMethod}
          seedingMethodError={seedingMethodError}
          setSeedingMethodError={setSeedingMethodError}
          setReseedDateError={setReseedDateError}
          grassType={newGrassType}
          notes={notes}
          seedingMethod={seedingMethod}
        />
      </StyledContainer>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          {f({ id: "addGrassType.cancel.label" })}
        </Button>
        <Button color="primary" onClick={handleSave}>
          {t("addGrassType.save.label")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
