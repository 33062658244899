import {
  Container,
  List,
  ListItem,
  Divider,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import { useTranslation } from "common/locales";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import FeedPurchaseEventList from "./FeedPurchaseEventList";
import { mixpanel } from "common/analytics";
import {
  selectFeedStockById,
  FeedStocks,
  FeedStock,
  useAppSelector,
  GENERAL_FEED_UNITS,
} from "model";
import { convertToFeedStockAmountString } from "./generalMethods";

export default function FeedPurchaseEventDetail() {
  const history = useHistory();
  const { t } = useTranslation();
  const feedStocks: FeedStocks | undefined = useAppSelector((state) =>
    selectFeedStockById(state, 1)
  );
  const locale = useAppSelector((state) => state.app.locale);

  return (
    <>
      <Container>
        <List>
          <ListItem key="add-feed-purchase">
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                mixpanel.track("Add new feed purchase event clicked");
                history.push(`/enter-feed-purchase-events`);
              }}
            >
              {t("feedPurchaseDetailView.button.addFeedPurchase.title")}
            </Button>
          </ListItem>
          <ListItem key="add-feed-event">
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                mixpanel.track("Add new feed event clicked");
                history.push(`/enter-feed-events`);
              }}
            >
              {t("feedEventDetailView.button.addFeedEvent.title")}
            </Button>
          </ListItem>
        </List>
      </Container>
      <Divider />
      <FieldEventListContainer>
        <Box
          my={4}
          display="flex"
          alignItems="center"
          gap={4}
          p={0}
          sx={{ border: "2px solid grey", borderRadius: 2 }}
        >
          <Container sx={{ display: "flex", flexDirection: "column" }}>
            <TableContainer>
              <Table
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: "none",
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography variant="h5">
                        {t("feed.stock.summary.header")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {feedStocks && feedStocks.feedStock.length > 0 ? (
                    feedStocks.feedStock.map((feedStock: FeedStock) => {
                      return (
                        <TableRow key={`${feedStock.type}`}>
                          <TableCell>
                            <Typography variant="body1">
                              {t(
                                `feedPurchase.feedType.option.${feedStock.type}`
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1">
                              {convertToFeedStockAmountString(
                                feedStock.amount,
                                locale,
                                GENERAL_FEED_UNITS.KG,
                                t
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell>
                        {" "}
                        <Typography variant="body1">
                          {t("feedStock.summary.noFeedAvailable")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </Box>
      </FieldEventListContainer>
      <FieldEventListContainer>
        <FeedPurchaseEventList />
      </FieldEventListContainer>
    </>
  );
}

const FieldEventListContainer = styled(Container)`
  flex-grow: 1;
`;
