import { Grid, InputLabel, TextField, Typography } from "@mui/material";
import { useTranslation } from "common/locales";
import GrassTypeDropdown from "../fieldEdit/GrassTypeDropdown";
import { Field, GRASS_TYPE } from "model/fieldSlice";
import { SEEDING_METHOD } from "model/reseedEventSlice";
import EnterDate from "../general/EnterDate";
import { Dispatch, SetStateAction } from "react";
import SeedingMethodDropdown from "features/bulkReseedEventInput/ReseedMethodDropDown";

interface Props {
  disabled: boolean;
  field: Field;
  setNewGrassType: Dispatch<SetStateAction<GRASS_TYPE | undefined>>;
  setNotes: Dispatch<SetStateAction<string | undefined>>;
  reseedDate: Date | null;
  setReseedDate: Dispatch<SetStateAction<Date | null>>;
  grassType: GRASS_TYPE | undefined;
  grassTypeError: boolean;
  setGrassTypeError: Dispatch<SetStateAction<boolean>>;
  reseedDateError: boolean;
  setReseedDateError: Dispatch<SetStateAction<boolean>>;
  notes: string | undefined;
  seedingMethodError: boolean;
  setSeedingMethodError: Dispatch<SetStateAction<boolean>>;
  setSeedingMethod: Dispatch<SetStateAction<SEEDING_METHOD | undefined>>;
  seedingMethod?: string;
}

export default function EnterReseedEvent({
  disabled,
  field,
  setNewGrassType,
  setNotes,
  reseedDate,
  setReseedDate,
  grassType,
  seedingMethod,
  grassTypeError,
  setGrassTypeError,
  setSeedingMethod,
  seedingMethodError,
  setSeedingMethodError,
  reseedDateError,
  setReseedDateError,
  notes,
}: Props) {
  const { t } = useTranslation();

  const handleGrassTypeChange = (value: GRASS_TYPE | undefined) => {
    setNewGrassType(value);
  };

  const handleSeedingMethodChange = (value: SEEDING_METHOD | undefined) => {
    setSeedingMethod(value);
  };

  return (
    <Grid
      container
      rowSpacing={3}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      flexDirection={"row"}
    >
      <Grid item xs={12} sm={12}>
        <Typography variant="h5">
          {disabled
            ? t("viewReseedEvent.heading")
            : t("editReseedEvent.heading")}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <EnterDate
          disabled={disabled}
          date={reseedDate}
          setDate={setReseedDate}
          dateError={reseedDateError}
          name="someName"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={2} flexDirection={"column"}>
          <Grid item xs={12} sm={8}>
            <InputLabel
              id="assignedGroup-select-label"
              variant="standard"
              shrink={true}
            >
              {t("editReseedEvent.field.heading")}
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={6}>
            {field.name}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <GrassTypeDropdown
          disabled={disabled}
          onChange={(value?: GRASS_TYPE) => {
            handleGrassTypeChange(value);
            if (value) {
              setGrassTypeError(false);
            }
          }}
          currentGrassType={grassType}
          grassTypeInputError={grassTypeError}
          label={"reseedEvent.grassType.text"}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SeedingMethodDropdown
          onChange={(value?: SEEDING_METHOD) => {
            handleSeedingMethodChange(value);
            if (value) {
              setSeedingMethodError(false);
            } else {
              setSeedingMethodError(true);
            }
          }}
          seedingMethod={seedingMethod ?? ""}
          seedingMethodInputError={seedingMethodError}
          label={"reseedEvent.seedingMethod.text"}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          defaultValue={notes}
          variant="standard"
          disabled={disabled}
          id="reseedNotes"
          onChange={(event) => {
            setNotes(event.target.value);
          }}
          label={t("reseedEvent.notes.label")}
          multiline
          maxRows={12}
        />
      </Grid>
    </Grid>
  );
}
