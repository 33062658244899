import { Grid, TextField, SelectChangeEvent, Button } from "@mui/material";
import { useTranslation } from "common/locales";
import { FormikErrors } from "formik";
import GenericYieldDropdown from "./GenericYieldDropdown";
import React from "react";
import { ButtonContainer } from "./generalMethods";
import { YieldDetailsFormData } from "./EnterYieldEvent";
import { SOLD_OR_KEPT } from "model";

import { useEffect } from "react";

interface Props {
  index: number;
  yieldDetail: YieldDetailsFormData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleInputChange: (event: any, name: string) => void;
  errors?: FormikErrors<YieldDetailsFormData>;
  remove: (index: number) => void;
  possibleYieldTypeValues: { [key: string]: string };
  yieldTypeTPrefix: string;
  possibleUnitTypeValues: { [key: string]: string };
  unitTypeTPrefix: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

export default function EnterYieldEventYieldDetails<YT>({
  index,
  yieldDetail,
  handleInputChange,
  errors,
  remove,
  possibleYieldTypeValues,
  yieldTypeTPrefix,
  possibleUnitTypeValues,
  unitTypeTPrefix,
  setFieldValue,
}: Props) {
  const { t } = useTranslation();

  useEffect(() => {
    if (yieldDetail.detailYieldType) {
      if (!(yieldDetail.detailYieldType in possibleYieldTypeValues)) {
        setFieldValue(`yieldAmounts[${index}].detailYieldType`, "", true);
      }
    }
  }, [
    possibleYieldTypeValues,
    index,
    setFieldValue,
    yieldDetail.detailYieldType,
  ]);

  return (
    <Grid
      columnSpacing={{ xs: 1, sm: 1, md: 2 }}
      container
      flexDirection={"row"}
      sx={{
        justifyItems: "baseline",
        justifySelf: "baseline",
        alignSelf: "baseline",
        paddingBottom: 5,
      }}
      display="flex"
      alignItems="baseline"
    >
      <Grid item xs={12} sm={2} display="flex" alignItems="baseline">
        <GenericYieldDropdown
          disabled={false}
          onChange={(event) => {
            handleInputChange(event, `yieldAmounts[${index}].detailYieldType`);
          }}
          currentValue={yieldDetail.detailYieldType}
          inputError={
            errors?.detailYieldType ||
            (yieldDetail.detailYieldType &&
              !(yieldDetail.detailYieldType in possibleYieldTypeValues))
              ? true
              : false
          }
          label={"yieldEvent.yieldType.text"}
          name={`yieldAmounts[${index}].detailYieldType`}
          possibleValues={possibleYieldTypeValues}
          tPrefix={yieldTypeTPrefix}
        />
      </Grid>
      <Grid item xs={12} sm={2} display="flex" alignItems="baseline">
        <GenericYieldDropdown
          disabled={false}
          onChange={(event) => {
            handleInputChange(event, `yieldAmounts[${index}].unitType`);
          }}
          currentValue={yieldDetail.unitType}
          inputError={errors?.unitType ? true : false}
          label={"yieldEvent.unitType.text"}
          name={`yieldAmounts[${index}].unitType`}
          possibleValues={possibleUnitTypeValues}
          tPrefix={unitTypeTPrefix}
        />
      </Grid>

      <Grid item xs={12} sm={2} display="flex" alignItems="baseline">
        <GenericYieldDropdown
          disabled={false}
          onChange={(event) => {
            handleInputChange(event, `yieldAmounts[${index}].soldOrKept`);
          }}
          currentValue={yieldDetail.soldOrKept}
          inputError={errors?.soldOrKept ? true : false}
          label={"yieldEvent.soldOrKept.text"}
          name={`yieldAmounts[${index}].soldOrKept`}
          possibleValues={SOLD_OR_KEPT}
          tPrefix={"yieldEvent.soldOrKept.label"}
        />
      </Grid>
      <Grid item xs={12} sm={2} display="flex" alignItems="baseline">
        <TextField
          required
          value={yieldDetail.amount}
          type="number"
          variant="standard"
          disabled={false}
          id="amount"
          error={errors?.amount ? true : false}
          onChange={(event) => {
            handleInputChange(event, `yieldAmounts[${index}].amount`);
          }}
          label={t("yieldEvent.yieldAmount.label")}
          maxRows={1}
          name={`yieldAmounts[${index}].amount`}
        />
      </Grid>
      <Grid item xs={12} sm={2} display="flex" alignItems="baseline">
        {yieldDetail.soldOrKept === SOLD_OR_KEPT.SOLD && (
          <TextField
            value={yieldDetail.revenue}
            type="number"
            variant="standard"
            disabled={false}
            id="revenue"
            error={errors?.revenue ? true : false}
            onChange={(event) => {
              handleInputChange(event, `yieldAmounts[${index}].revenue`);
            }}
            label={t("yieldEvent.revenue.label")}
            maxRows={1}
            name={`yieldAmounts[${index}].revenue`}
          />
        )}
      </Grid>
      {index > 0 && (
        <Grid item xs={12} sm={2} display="flex" alignItems="baseline">
          <ButtonContainer>
            <Button color="secondary" onClick={() => remove(index)}>
              {t("yieldEventInput.removeYield")}
            </Button>
          </ButtonContainer>
        </Grid>
      )}
    </Grid>
  );
}
