import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityId,
} from "@reduxjs/toolkit";
import { API } from "common/utils";
import { changeGrassType, GRASS_TYPE, selectAllFields } from "./fieldSlice";
import { RootState } from "./rootReducer";

export const FIELD_RESEEDED_EVENT_REJECTION_PERIOD_DAYS = 30;

export enum SEEDING_METHOD {
  PLOUGH_AND_POWER_HARROW = "PLOUGH_AND_POWER_HARROW",
  DIRECT_DRILL = "DIRECT_DRILL",
  SHALLOW_CULTIVATION = "SHALLOW_CULTIVATION",
  OVERSEED = "OVERSEED",
}

export type ReseedEvent = {
  guid: string;
  note: string;
  reseedDate: string;
  newGrassType: GRASS_TYPE;
  seedingMethod: SEEDING_METHOD;
  fieldGuid: string;
  creationDate?: string;
};

class ReseedAPI extends API<ReseedEvent> {
  async getAllReseedEventsForFarm(
    guid: string,
    reseedTimeAfter?: string
  ): Promise<ReseedEvent[]> {
    const reseedTimeAfterParam = reseedTimeAfter
      ? `&reseedTimeAfter=${reseedTimeAfter}`
      : "";
    const res = await fetch(
      `${this.baseUrl}${this.entity}?farmGuid=${guid}${reseedTimeAfterParam}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("jwt") || ""}`,
          "Content-Type": "application/json",
        },
      }
    );
    return (await res.json()) as ReseedEvent[];
  }
}

const reseedApi = new ReseedAPI("reseed");

export const createReseedEvent = createAsyncThunk(
  "reseed/create",
  async (reseedEvent: ReseedEvent, { dispatch }) => {
    await reseedApi.createOne(reseedEvent);
    dispatch(
      changeGrassType({
        grassType: reseedEvent.newGrassType,
        guid: reseedEvent.fieldGuid,
      })
    );
  }
);

export const updateReseedEvent = createAsyncThunk(
  "reseed/create",
  async (reseedEvent: ReseedEvent, { dispatch }) => {
    await reseedApi.updateOne(reseedEvent);
  }
);

export const reseedEventAdapter = createEntityAdapter<ReseedEvent>({
  // we need this because IDs are stored in a field other than `field.id`
  selectId: (reseedEvent) => reseedEvent.guid,
  // Keeps the array sorted by guid
  sortComparer: (a, b) => a.guid.localeCompare(b.guid),
});

export const {
  selectAll: selectAllReseedEvents,
  selectById: selectReseedEventById,
} = reseedEventAdapter.getSelectors((state: RootState) => state.reseedEvents);

const selectItemId = (state: RootState, itemId: string) => itemId;
export const selectAllReseedEventsForField = createSelector(
  [selectAllReseedEvents, selectItemId],
  (readings, fieldId) => readings.filter((curr) => curr.fieldGuid === fieldId)
);

export const selectFieldsWithReseedEvents = createSelector(
  [selectAllReseedEvents, selectAllFields],
  (reseedEvents, fields) => {
    const fieldGuids = new Set(reseedEvents.map((r) => r.fieldGuid));
    const result = fields.filter((f) => fieldGuids.has(f.guid));
    return result;
  }
);

const initialreseedEventState: EntityState<ReseedEvent> =
  reseedEventAdapter.getInitialState({});

export const getAllReseedEventsForFarm = createAsyncThunk<
  Promise<Array<ReseedEvent>>,
  { farmId: string; reseedTimeAfter?: string }
>("readings/getReseedEventsBulk", async ({ farmId, reseedTimeAfter }) => {
  return reseedApi.getAllReseedEventsForFarm(farmId, reseedTimeAfter);
});

const reseedEventSlice = createSlice({
  name: "reseedEvent",
  initialState: initialreseedEventState,
  reducers: {},
  extraReducers: {
    [getAllReseedEventsForFarm.fulfilled.type]: (state, action) => {
      reseedEventAdapter.setAll(
        state,
        action.payload as readonly ReseedEvent[] | Record<EntityId, ReseedEvent>
      );
    },
  },
});

export const reseedEventReducer = reseedEventSlice.reducer;
