import { Button, Grid, TextField, Typography } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "common/locales";
import StyledCoverAppBar from "../bulkCoverInput/StyledCoverAppBar";
import GrassTypeDropdown from "../fieldEdit/GrassTypeDropdown";
import { Field, GRASS_TYPE, selectAllFields } from "model/fieldSlice";
import { SEEDING_METHOD } from "model/reseedEventSlice";
import { useIntl } from "react-intl";
import EnterDate from "../general/EnterDate";
import { Dispatch, MouseEventHandler, SetStateAction } from "react";
import SeedingMethodDropdown from "./ReseedMethodDropDown";
import { mixpanel } from "common/analytics";
import NameAndGuidMultiSelectDropdown, {
  NameAndGuid,
} from "features/general/NameAndGuidMultiSelectDropdown";

interface Props {
  selectedFields: NameAndGuid[];
  setSelectedFields: Dispatch<SetStateAction<Array<NameAndGuid>>>;
  setNewGrassType: Dispatch<SetStateAction<GRASS_TYPE | undefined>>;
  setSeedingMethod: Dispatch<SetStateAction<SEEDING_METHOD | undefined>>;
  handleSave: MouseEventHandler<HTMLButtonElement>;
  setNotes: Dispatch<SetStateAction<string | undefined>>;
  reseedDate: Date | null;
  setReseedDate: Dispatch<SetStateAction<Date | null>>;
  grassTypeError: boolean;
  setGrassTypeError: Dispatch<SetStateAction<boolean>>;
  seedingMethodError: boolean;
  setSeedingMethodError: Dispatch<SetStateAction<boolean>>;
  reseedDateError: boolean;
  setReseedDateError: Dispatch<SetStateAction<boolean>>;
  fieldsSelectInputError: boolean;
  setFieldsSelectInputError: Dispatch<SetStateAction<boolean>>;
  newGrassType?: string;
  seedingMethod?: string;
}

export default function EnterReseedEvents({
  selectedFields,
  setSelectedFields,
  setNewGrassType,
  handleSave,
  setNotes,
  reseedDate,
  setReseedDate,
  grassTypeError,
  setGrassTypeError,
  setSeedingMethod,
  seedingMethodError,
  setSeedingMethodError,
  reseedDateError,
  setReseedDateError,
  fieldsSelectInputError,
  setFieldsSelectInputError,
  newGrassType,
  seedingMethod,
}: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { formatMessage: f } = useIntl();
  const allFields = useSelector(selectAllFields);

  const handleGrassTypeChange = (value: GRASS_TYPE | undefined) => {
    setNewGrassType(value);
  };

  const handleSeedingMethodChange = (value: SEEDING_METHOD | undefined) => {
    setSeedingMethod(value);
  };

  return (
    <Grid
      container
      rowSpacing={3}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      flexDirection={"column"}
    >
      <Grid item xs={12} sm={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h5">{t("reseedEvent.heading")}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <EnterDate
              disabled={false}
              date={reseedDate}
              setDate={setReseedDate}
              dateError={reseedDateError}
              name={"someName"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <NameAndGuidMultiSelectDropdown
              required={false}
              selectedItems={selectedFields}
              items={allFields.map((field: Field) => {
                return {
                  name: field.name,
                  guid: field.guid,
                };
              })}
              disabled={false}
              onChange={(e: SelectChangeEvent<string[]>) => {
                let selectedGuids: string[] = [];
                if (
                  typeof e.target.value === "string" ||
                  e.target.value instanceof String
                ) {
                  selectedGuids = e.target.value.split(",");
                } else {
                  selectedGuids = e.target.value;
                }
                const selectedFieldsList: NameAndGuid[] = [];
                selectedGuids.forEach((fieldGuid: string) => {
                  for (const field of allFields) {
                    if (field.guid === fieldGuid) {
                      selectedFieldsList.push({
                        guid: field.guid,
                        name: field.name,
                      });
                    }
                  }
                });

                setSelectedFields(selectedFieldsList);
                setFieldsSelectInputError(
                  !selectedFieldsList || selectedFieldsList.length <= 0
                );
              }}
              selectInputError={fieldsSelectInputError}
              label={t("reseedEvents.selectFields.text")}
              name={"someName"}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <GrassTypeDropdown
              disabled={false}
              onChange={(value?: GRASS_TYPE) => {
                handleGrassTypeChange(value);
                if (value) {
                  setGrassTypeError(false);
                }
              }}
              currentGrassType={newGrassType ?? ""}
              grassTypeInputError={grassTypeError}
              label={"reseedEvent.grassType.text"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <SeedingMethodDropdown
              onChange={(value?: SEEDING_METHOD) => {
                handleSeedingMethodChange(value);
                if (value) {
                  setSeedingMethodError(false);
                } else {
                  setSeedingMethodError(true);
                }
              }}
              seedingMethod={seedingMethod ?? ""}
              seedingMethodInputError={seedingMethodError}
              label={"reseedEvent.seedingMethod.text"}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="standard"
              disabled={false}
              id="reseedNotes"
              onChange={(event) => {
                setNotes(event.target.value);
              }}
              label={t("reseedEvent.notes.label")}
              multiline
              maxRows={12}
            />
          </Grid>
        </Grid>
      </Grid>
      <StyledCoverAppBar>
        <Button
          onClick={() => {
            mixpanel.track("Enter re-seed event cancelled");
            history.goBack();
          }}
          color="secondary"
        >
          {f({ id: "addGrassType.cancel.label" })}
        </Button>
        <Button color="primary" onClick={handleSave}>
          {t("addGrassType.save.label")}
        </Button>
      </StyledCoverAppBar>
    </Grid>
  );
}
