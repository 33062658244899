import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { differenceInDays } from "date-fns";
import {
  useAppSelector,
  ReseedEvent,
  selectFieldById,
  FIELD_RESEEDED_EVENT_REJECTION_PERIOD_DAYS,
} from "model";
import { useTranslation } from "common/locales";
import { getFormattedTime } from "../../common/utils/getFormattedTime";
import { SwardCompositionIcon } from "common/icons";
import { MoreVert } from "@mui/icons-material";

import { useState } from "react";
import UpdateReseedEventDialog from "features/singleReseedEventInput/UpdateReseedEventDialog";

export default function ReseedEventListItem({
  reseedEvent,
  showFieldName,
}: {
  reseedEvent: ReseedEvent;
  showFieldName: boolean;
}) {
  const { t } = useTranslation();
  const locale = useAppSelector((state) => state.app.locale);

  const icon = <SwardCompositionIcon />;

  const title = showFieldName
    ? "reseedEvents.show.titleName"
    : "reseedEvents.show.title";
  const { reseedDate, newGrassType } = reseedEvent;
  const formattedTime = getFormattedTime(reseedDate, locale);
  const field = useAppSelector((state) =>
    selectFieldById(state, reseedEvent.fieldGuid!)
  );

  const grassType = t(`fieldinput.grassType.option.${newGrassType}`);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  if (!field) {
    return null;
  }

  function openMenu(event: React.MouseEvent<HTMLButtonElement>) {
    setMenuAnchor(event.currentTarget);
  }

  function closeMenu() {
    setMenuAnchor(null);
  }

  const canEdit =
    differenceInDays(
      Date.now(),
      new Date(reseedEvent.creationDate!) ?? Date.now()
    ) <= FIELD_RESEEDED_EVENT_REJECTION_PERIOD_DAYS;

  return (
    <ListItem key={`re-listitem-${reseedEvent.guid}`}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primary={t(title, { name: field.name })}
        secondary={
          <>
            <Typography component="span" display="block">
              {t("reseedEvent.reseedDate", {
                reseedDate: formattedTime,
              })}
            </Typography>
            <Typography component="span" display="block">
              {t("reseedEvent.newGrassType", { newGrassType: grassType })}
            </Typography>
            {reseedEvent.seedingMethod && (
              <Typography component="span">
                {t("reseedEvent.seedingMethod", {
                  seedingMethod: t(
                    `reseedEvent.seedingMethod.option.${reseedEvent.seedingMethod}`
                  ),
                })}
              </Typography>
            )}
            {reseedEvent.note && (
              <Typography component="span" display="block">
                {t("reseedEvent.note", { note: reseedEvent.note })}
              </Typography>
            )}
          </>
        }
      />
      {canEdit && (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-haspopup="true"
            onClick={openMenu}
            id={reseedEvent.guid}
            size="large"
          >
            <MoreVert />
          </IconButton>
          <UpdateReseedEventDialog
            closeMenu={closeMenu}
            menuAnchor={menuAnchor}
            reseedEvent={reseedEvent}
          />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
