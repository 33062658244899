import { List, ListItem, ListItemText } from "@mui/material";
import { useAppSelector, ReseedEvent, selectAllReseedEvents } from "model";
import { useTranslation } from "common/locales";
import ReseedEventListItem from "features/fieldEventList/ReseedEventListItem";
export default function FarmReseedEventList() {
  const { t } = useTranslation();

  const reseedEventsForFarm: Array<ReseedEvent> = useAppSelector((state) =>
    selectAllReseedEvents(state)
  );

  const sortedReseedEventsForFarm = reseedEventsForFarm.sort(
    (a: ReseedEvent, b: ReseedEvent) =>
      Date.parse(b.reseedDate).valueOf() - Date.parse(a.reseedDate).valueOf()
  );

  return (
    <List>
      <ListItem>
        <ListItemText primary={t("reseedEvent.list.title")} />
      </ListItem>
      {sortedReseedEventsForFarm.map((reseedEvent: ReseedEvent) => (
        <ReseedEventListItem reseedEvent={reseedEvent} showFieldName={true} />
      ))}
    </List>
  );
}
